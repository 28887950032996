import React from "react";
import Navbar from "./components/Navbar.js";
import Footer from "./components/Footer.js";
import MyStory from "./components/MyStory.js";
import MyBooks from "./components/MyBooks.js";
import Contact from "./components/Contact.js";
/* import Booking from "./components/Booking.js";*/
import heroImage from "./ottherly_hero_img.svg";

export default function Landing() {
  return (
    <>
      <Navbar transparent />
      <main>
        <div className="hero" 
        style={{ 
          backgroundImage: `url(${heroImage})`,
          backgroundSize: "cover",
          minHeight:"75vh",
        }}>
        </div>
        <svg class="animate-bounce w-6 h-6 mx-auto mt-4 mb-10 text-amber-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
        </svg>
        <div className="container mx-auto flex justify-center items-center">
            <div className="w-full md:w-6/12 px-4">
              <p className="text-lg font-light leading-relaxed text-gray-700">
              Alexa is the name that was given as a vision of the journey towards an authentic soul expression in unity. 
              To learn to understand what is, and what can become, if you open up to the healing process within to see; 
              that we are whole. A transmission from what you thought you were to the one you authentically are.
              </p>
            </div>
          </div>

        <MyStory />
        <MyBooks />
        {/* 
        <Booking />
        */} 
        <Contact />
      </main>
      <Footer />
    </>
  );
}
