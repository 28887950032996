import React from "react";

export default function MyBooks() {
  return (
    <section id="myBooks" className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <a href="https://www.google.no/books/edition/The_Journey_towards_Love/OZoXEAAAQBAJ?hl=no&gbpv=1&dq=annette+torp&printsec=frontcover" target="_blank" rel="noreferrer">
                  <img
                    alt="The Journey Towards Love Book Cover by Annette Torp"
                    className="max-w-full rounded-lg mb-10"
                    src="/books-annette_torp.png" />
                </a>
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <h2 className="text-4xl text-center text-amber-800 font-semibold hover:font-serif">
                    My Books
                  </h2>
                  <p className="mt-4 text-lg leading-relaxed text-gray-700">
                    <strong>The Journey Towards Love:</strong> 
                    A poetry book about the writer's reflections from reading and experiencing life with its ups and downs, 
                    light and dark and to see failures not as failures when you learn something from them.
                  </p>
                  <a className="mt-4 text-lg text-right leading-relaxed text-amber-800 font-medium underline dark:text-amber-500 dark:hover:text-amber-600 hover:text-amber-700 hover:no-underline" 
                  href="https://alexa-author.ck.page/products/the-journey-towards-love" 
                  target="_blank" rel="noreferrer">Click here to Buy
                  </a>
                  <p className="mt-4 text-lg leading-relaxed text-gray-700">
                    <strong>Ottherly:</strong> 
                    A fiction-poetry novel that explores spirituality through the interactions between an otter and her environment.
                    Through her meetings and conversations, she explores and reflects upon her life. 
                  </p>
                  <a className="mt-4 text-lg text-right leading-relaxed text-amber-800 font-medium underline dark:text-amber-500 dark:hover:text-amber-600 hover:text-amber-700 hover:no-underline" 
                  href="https://alexa-author.ck.page/products/ottherly-poetry" 
                  target="_blank" rel="noreferrer">Click here to Buy
                  </a> 
                  <p className="mt-4 text-lg leading-relaxed text-gray-700">
                    <strong>Reisen Hjem(currently just in Norwegian):</strong> 
                    It is a transformative journey through trauma and the power of hope. 
                    In this compelling teaching memoir, Annette invites you to explore your inner world, 
                    with each chapter unveiling new layers of understanding, healing, and self-discovery. 
                    Delve into profound themes like trauma, attachment, and biological activation in the face of life’s challenges. 
                    Annette offers not just insights and theory, but tools and reflection exercises to guide your own healing journey.
                    Through inspiring thoughts, wisdom, and personal reflections, you’ll find guidance and support along the way. 
                    "Reisen Hjem" is more than a book; it's a companion for anyone seeking change and growth.
                  </p>
                  <a className="mt-4 text-lg text-right leading-relaxed text-amber-800 font-medium underline dark:text-amber-500 dark:hover:text-amber-600 hover:text-amber-700 hover:no-underline" 
                  href="https://alexa-author.ck.page/reisenhjem" 
                  target="_blank" rel="noreferrer">Click here to Buy.
                  </a>
                </div>
              </div>
            </div>
        </div>
    </section>
  );
}