import React from "react";

export default function MyStory() {
  return (
    <section id="myStory" className="relative py-20">
        <div
        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style={{ height: "80px" }}>
        </div>
        <div className="container mx-auto px-4">
            <div className="flex flex-wrap items-center mt-32">
                <div className="w-full md:w-5/12 px-4">
                        <h1 className="text-4xl text-amber-800 mb-2 font-semibold text-center justify-center hover:font-serif">
                        My Story
                        </h1>
                        <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700">
                        <strong>Annette</strong> is a Norwegian poet and writer with a fascination for humankind and our different, however similar,  journeys. 
                        Her creative side through poetry has always been present, but especially in her teens where this gave her an outlet for her thoughts and feelings.
                        </p><br />
                        <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700">
                        Her educational background is diverse and includes three bachelor’s degrees: one in Social work, the second in Project management Arts and Creative industry, and the last one in Psychology. 
                        She also has a diploma as a Massage therapist, a 200 hours certification as a Yoga teacher, courses in Rosenmethod and Tibetan healing.
                        She writes about topics that she feels called to do, to explore new territory and expand to grow more into her authentic expression.
                        </p>
                </div>
                
                <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
                                <img
                                alt="annette portrait"
                                src="/annette_s1.jpg"
                                className="w-full align-middle rounded-t-lg" />
                        </div>
                </div>
            </div>
        </div>
    </section>
  );
}