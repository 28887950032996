import React, { useRef } from 'react';
import Swal from 'sweetalert2';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    // Basic validation
    const userName = form.current.user_name.value;
    const userEmail = form.current.user_email.value;
    const userMessage = form.current.user_message.value;

    if (!userName || !userEmail || !userMessage) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Please fill in all fields.',
        confirmButtonColor: '#92400e',
        color:'#374151',
      });
      return;
    }

    // Validate email using a simple regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userEmail)) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Please enter a valid email address.',
        confirmButtonColor: '#92400e',
        color:'#374151',
      });
      return;
    }

    // Proceed with sending the email
    try {
      const result = await emailjs.sendForm('service_3uix8br', 'template_4u9zcza', form.current, 'f55-2OUsuEvuueU1z');
      console.log(result.text);

      // Display success message
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Your message has been sent successfully.',
        confirmButtonColor: '#92400e',
        color:'#374151',
      });

      // Reload the page after a short delay (adjust the delay as needed)
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.log(error.text);

      // Display error message
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while sending your message. Please try again later.',
        confirmButtonColor: '#92400e',
        color:'#374151',
      });
    }
  };

  return (
    <section id="contact" className="py-20 relative block">
      <div className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"></div>

      <div className="container mx-auto px-4 pt-10 pb-24 lg:pt-24 lg:pb-64">
        <div className="flex flex-wrap text-center justify-center">
          <div className="w-full px-4">
            <h1 className="text-4xl font-semibold text-amber-800 hover:font-serif mb-10">
              Contact
            </h1>
          </div>
        </div>
      </div>

      <form ref={form} onSubmit={sendEmail} method="post" className="relative block py-24 lg:pt-0">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
                <div className="flex-auto p-5 lg:p-10">
                  <h4 className="text-2xl font-semibold text-gray-700">
                    You are welcome to send me suggestions of content you wish to know more about...
                  </h4>
                  <div className="relative w-full mb-3 mt-8">
                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="full-name">
                      Full Name
                    </label>
                    <input
                      type="text"
                      name="user_name"
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder="Full Name"
                      style={{ transition: "all .15s ease" }}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="email"
                      name="user_email"
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder="Email"
                      style={{ transition: "all .15s ease" }}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="message">
                      Message
                    </label>
                    <textarea
                      rows="4"
                      cols="80"
                      name="user_message"
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder="Type a message..."
                    />
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-orange-300 text-amber-800 active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                      type="submit"
                      value="Send"
                      style={{ transition: "all .15s ease" }}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default Contact;
