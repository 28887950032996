import React from 'react'
import Newsletter from './Newsletter';
import PayPalDonateButton from './PayPalDonateButton';
import { SocialIcon } from 'react-social-icons'
  
export default function Footer() {

  return (
    <footer className="relative bg-orange-300 pt-8 pb-6">
      <div
        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style={{ height: "80px" }}
      >
      </div>
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap text-center lg:text-left justify-center">
          <div className="w-full lg:w-4/12 px-4">
            <h4 className="text-3xl font-semibold text-amber-800">
              Let's keep in touch!
            </h4>
            <h5 className="text-lg mt-0 mb-2 text-gray-700">
              Find me on any of these platforms.
            </h5>
              <SocialIcon url="https://www.instagram.com/annettetorp/" bgColor="#92400e" px-4  target="_blank" rel="noreferrer" />
              <SocialIcon url="https://www.linkedin.com/in/annette-torp-97a91b65/" bgColor="#92400e"  target="_blank" rel="noreferrer" />
              <SocialIcon url="https://linktr.ee/annettetorp" bgColor="#92400e"  target="_blank" rel="noreferrer" />
              <SocialIcon url="https://www.youtube.com/@AnnetteTorp" bgColor="#92400e"  target="_blank" rel="noreferrer" />
          </div>  
          <div className="w-full lg:w-4/12  mx-auto p-5">
            <p className="text-2xl text-gray-700">
              Heal yourself 
            </p>
            <p className="text-2xl text-gray-700">
              Be yourself 
            </p>
            <p className="text-2xl text-gray-700">
              Be
            </p>
          </div>
          <div className="w-full lg:w-4/12">
          <h4 className="text-3xl font-semibold text-amber-800">
              Join our Newsletter
          </h4>
            <Newsletter />
          </div>
          <div className="block mx-auto">
            <PayPalDonateButton />
          </div>
        </div>
        
        <hr className="my-6 border-gray-400" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-gray-600 font-semibold py-1">
              Copyright © {new Date().getFullYear()}{" "}ALEXA{" "}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}