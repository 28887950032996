import React, {useEffect} from "react";
import Hamburger from 'hamburger-react'
import { Link } from "react-scroll";

export default function Navbar({ fixed }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  
  return (
    <>
      <nav className=" fixed w-full z-20 top-0 left-0 sticky relative flex flex-wrap items-center justify-between px-2 py-3 bg-gradient-to-r from-amber-800 to-amber-500 ...">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <button
              onClick={() => {
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
              }}
            >
              <img
                  alt="Alexa logo"
                  className="w-40  mx-auto"
                  src="/alexa_logo.png"/>
            </button>
            <div className="cursor-pointer lg:hidden outline-none focus:outline-none">
              <Hamburger  rounded color="#FFFFFF" toggled={navbarOpen} toggle={setNavbarOpen}/>
            </div>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:mx-auto">
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="#myStory"
                >
                  <i className="text-lg leading-lg text-white opacity-75"></i><Link activeClass="active" smooth spy to="myStory">My Story</Link>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="#myBooks"
                >
                  <i className="text-lg leading-lg text-white opacity-75"></i><Link activeClass="active" smooth spy to="myBooks">My Books</Link>
                </a>
              </li>
              {/* 
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="#ongoingProjects"
                >
                  <i className="text-lg leading-lg text-white opacity-75"></i><Link activeClass="active" smooth spy to="ongoingProjects">Ongoing Projects</Link>
                </a>
              </li>
              */} 
              {/* 
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="#calendly"
                >
                  <i className="text-lg leading-lg text-white opacity-75"></i><Link activeClass="active" smooth spy to="calendly">Book a Meeting</Link>
                </a>
              </li>
              */}
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="https://alexa057.webnode.page/"
                  target="_blank" // Add target="_blank" to open in a new window
                  rel="noopener noreferrer" // Add security attribute for external links
                >
                  <i className="text-lg leading-lg text-white opacity-75"></i>Blog
                </a>
              </li> 
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="#contact"
                >
                  <i className="text-lg leading-lg text-white opacity-75"></i><Link activeClass="active" smooth spy to="contact">Contact</Link>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
