import React, { useEffect, useRef } from 'react';

const PayPalDonateButton = () => {
  const scriptLoaded = useRef(false);

  useEffect(() => {
    const loadPayPalScript = () => {
      if (!scriptLoaded.current) {
        const script = document.createElement('script');
        script.src = 'https://www.paypalobjects.com/donate/sdk/donate-sdk.js';
        script.charset = 'UTF-8';
        document.head.appendChild(script);

        script.onload = initializePayPalButton;

        scriptLoaded.current = true;
      }
    };

    const initializePayPalButton = () => {
      window.PayPal.Donation.Button({
        env: 'production',
        hosted_button_id: process.env.REACT_APP_PAYPAL_ID, // Use environment variable if available
        image: {
          src: 'https://pics.paypal.com/00/s/ZjExZmE1OWMtNTI4My00M2U4LWE4NDktYzA3OTdjNjViYWU5/file.PNG',
          title: 'PayPal - The safer, easier way to pay online!',
          alt: 'Donate with PayPal button',
        },
        onComplete: handlePayPalComplete,
      }).render('#paypal-donate-button-container');
    };

    const handlePayPalComplete = (params) => {
      // Your onComplete handler
    };

    loadPayPalScript();
  }, []);

  return <div id="paypal-donate-button-container"></div>;
};

export default PayPalDonateButton;